import React from "react"
import { graphql } from "gatsby"
import { Container } from "components/library"
import BG from "images/labs_desktop_curved.svg"
import BG_MOBILE from "images/labs_mobile_curved.svg"
import { CtaBlock } from "components/common/cta-block"
import { OverviewFaqs } from "components/pages/products/overview/overview-faqs"
import { Hero } from "components/pages/products/overview/overview-hero"
import { AiEngine } from "components/pages/products/overview/overview-ai-engine"
import { ProductDevelopment } from "components/pages/products/overview/overview-product-development"
import { ManageYourData } from "components/pages/products/overview/overview-manage-your-data"
import { Rnd } from "components/pages/products/overview/overview-rnd"
import { CaseStudy } from "components/pages/products/overview/overview-case-study"

const ProductPage = () => {
    return (
        <main className="relative">
            <img
                src={BG}
                className="hidden sm:block w-full absolute sm:-top-[600px] left-0 z-[-1]"
                alt=""
            />

            <img
                src={BG_MOBILE}
                className="sm:hidden w-full absolute -top-[200px] left-0 z-[-1]"
                alt=""
            />

            <Container className="flex flex-col py-32 gap-20 sm:gap-32">
                <Hero />
                <AiEngine />
            </Container>
            <ProductDevelopment />
            <Container className="flex flex-col py-32 gap-20 sm:gap-32">
                <ManageYourData />
                <Rnd />
                <CaseStudy />
                <OverviewFaqs className="py-12" />
            </Container>

            <CtaBlock />
        </main>
    )
}

export default ProductPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
